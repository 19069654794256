import { AppBar, Toolbar, Button, Box, IconButton, Menu, MenuItem, Slide, useScrollTrigger } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useState, useEffect } from 'react';
import { getImageUrl } from '../../utils';

const logo = getImageUrl('pilates-logo-blue.png');

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Navbar = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HideOnScroll>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: '#fff',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          padding: { xs: '0.75rem 0', md: '1rem 0' },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Left Spacer for Centering */}
          <Box sx={{  display: { xs: 'none', md: 'flex' } }}></Box>
          
          {/* Centered Logo */}
          <Box sx={{ display: 'flex' }}>
            <Link to="/">
              <img src={logo} alt="Pilates Höganäs" style={{ height: '49px', width: 'auto', cursor: 'pointer' }} />
            </Link>
          </Box>
          
          {/* Desktop Navigation */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', flexGrow: 1, justifyContent: 'flex-end' }}>
            <Button
              component={Link}
              to="/sidepages/Schema"
              sx={{
                color: location.pathname === '/sidepages/Schema' ? '#007C85' : '#00b5bf',
                fontSize: '1.3rem',
                fontWeight: 'bold',
                textTransform: 'none',
                transition: 'color 0.3s ease',
                '&:hover': { color: '#007C85' },
              }}
            >
              Schema
            </Button>
            
            <Button
              onClick={handleMenu}
              sx={{
                color: '#00b5bf',
                fontSize: '1.3rem',
                fontWeight: 'bold',
                textTransform: 'none',
                transition: 'color 0.3s ease',
                '&:hover': { color: '#007C85' },
              }}
            >
              Meny
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {['Startsida', 'Medlemsskap', 'Klasser', 'Personlig Träning', 'Bra Att Veta', 'Om Pilates Höganäs'].map((item, index) => (
                <MenuItem
                  key={index}
                  component={Link}
                  to={
                    item === 'Medlemsskap'
                      ? '/sidepages/membership-info'
                      : item === 'Om Pilates Höganäs'
                      ? '/sidepages/about-info'
                      : item === 'Klasser'
                      ? '/sidepages/klasses'
                      : item === 'Personlig Träning'
                      ? '/sidepages/personaltraining-info'
                      : item === 'Bra Att Veta'
                      ? '/sidepages/bra-att-veta'
                      : '/'
                  }
                  onClick={handleClose}
                  sx={{ fontSize: '1rem', padding: '0.75rem 1.5rem' }}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
            
            <Button
              href="https://pilateshoganas.wondr.se/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#fff',
                marginLeft: '1.5rem',
                backgroundColor: '#00b5bf',
                padding: '0.75rem 2rem',
                borderRadius: '16px',
                fontWeight: '600',
                textTransform: 'none',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease',
                '&:hover': { backgroundColor: '#007C85', transform: 'translateY(-2px)' },
              }}
            >
              Logga in
            </Button>
          </Box>

          {/* Mobile Navigation */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
            
            <Button
              component={Link}
              to="/sidepages/Schema"
              sx={{
                color: '#00b5bf',
                fontSize: '1rem',
                fontWeight: 'bold',
                textDecoration:'underline',
                textTransform: 'none',
                transition: 'color 0.3s ease',
                '&:hover': { color: '#007C85' },
              }}
            >
              Schema
            </Button>
           
            <Button
              href="https://pilateshoganas.wondr.se/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#fff',
                backgroundColor: '#00b5bf',
                textAlign:'center',
                margin: '0.5rem',
                marginLeft:'0.1rem',
                padding: '0.5rem 0.5rem',
                borderRadius: '20px',
                fontSize:'0.6rem',
                fontWeight: '600',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#007C85' },
              }}
            >
              Logga in
            </Button>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenu} sx={{ color: '#00b5bf' }}>
              <MenuIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {['Startsida', 'Medlemsskap', 'Klasser', 'Personlig Träning', 'Bra Att Veta', 'Om Pilates Höganäs'].map((item, index) => (
                <MenuItem
                  key={index}
                  component={Link}
                  to={
                    item === 'Medlemsskap'
                      ? '/sidepages/membership-info'
                      : item === 'Om Pilates Höganäs'
                      ? '/sidepages/about-info'
                      : item === 'Klasser'
                      ? '/sidepages/klasses'
                      : item === 'Personlig Träning'
                      ? '/sidepages/personaltraining-info'
                      : item === 'Bra Att Veta'
                      ? '/sidepages/bra-att-veta'
                      : '/'
                  }
                  onClick={handleClose}
                  sx={{ fontSize: '1rem', padding: '0.75rem 1.5rem' }}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default Navbar;
