import { Container, Grid, Typography, Card, CardContent, CardMedia, Button, Box } from '@mui/material';
import { getImageUrl } from '../../utils';

const coachesData = [
  {
    name: 'Sandra Möller',
    description: 'Med en djup expertis inom Pilates blev Sandra kär i träningsformen när den gjorde henne smärtfri och starkare. Nu delar hon sin passion som certifierad Teacher Trainer och hjälper andra att nå sina mål!',
    image: getImageUrl('coach-sandra2.png'),
  },
  {
    name: 'Agnes Knutsson',
    description: 'Började med Pilates för att läka min rygg, och nu kan jag inte sluta! Certifierad i mattwork, TRX och mer—redo att stärka både kropp och sinne.',
    image: getImageUrl('coach-agnes2.png'),
  },
  {
    name: 'Johanna Magnelin',
    description: 'Ingen träningsfanatiker, bara förälskad i det som får mig att må bra! Jag hejar på dig varje steg i vår Pilatesresa tillsammans.',
    image: getImageUrl('coach-johanna3.png'),
  },
  {
    name: 'Malin Heimdahl',
    description: 'Träning ska vara rolig, fylld med skratt och energi! Jag blandar yogans lugn med Pilates styrka för en balanserad träningsupplevelse.',
    image: getImageUrl('coach-malin2.png'),
  },
  {
    name: 'Jennie Levin',
    description: 'Från periodare till morgonpigg instruktör—jag ger energi och variation i varje Pilates- och TRX-pass. Låt oss göra morgnarna roliga!',
    image: getImageUrl('coach-jennie2.png'),
  },
];

const Coaches = () => (
  <Container sx={{ padding: '4rem 0', backgroundColor: '#f9f9f9' }}>
    <Typography 
      variant="h4" 
      align="center" 
      sx={{ fontWeight: 'bold', color: '#333', marginBottom: '2rem' }}
    >
      Våra instruktörer
    </Typography>
    <Typography 
      variant="body1" 
      align="center" 
      paragraph 
      sx={{ color: '#666', marginBottom: '3rem' }}
    >
      Möt vårt team av certifierade och passionerade Pilates-instruktörer som är här för att hjälpa dig nå dina mål.
    </Typography>
    <Grid container spacing={3} justifyContent="center">
      {coachesData.map((coach, index) => (
        <Grid item xs={12} sm={6} md={4} lg={2.3} key={index}>
          <Card sx={{ 
            boxShadow: '0 4px 15px rgba(0,0,0,0.1)', 
            borderRadius: '12px',
            textAlign: 'center',
            height: '100%',
            filter: 'grayscale(100%)',
                  transition: 'filter 0.2s ease-in-out',
                  '&:hover': {
                    filter: 'grayscale(0%)',
                    transform: 'translateY(-8px)',
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
                  },
          }}>
            <Box sx={{ 
              position: 'relative', 
              paddingTop: '100%', 
              width: '100%', 
              overflow: 'hidden', 
              borderRadius: '12px 12px 0 0'
              
            }}>
              <CardMedia
                component="img"
                image={coach.image}
                alt={coach.name}
                sx={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover',
                  
                }}
              />
            </Box>
            <CardContent sx={{ padding: '1rem' }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 'bold', 
                  marginBottom: '0.5rem', 
                  color: '#333'
                }}
              >
                {coach.name}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ color: '#666' }}
              >
                {coach.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    
  </Container>
);

export default Coaches;
