import { Container, Grid, Typography, Box, Link as MUILink } from '@mui/material';
import { LinkedIn, MailOutline, Instagram, Facebook, LocationOn, Phone } from '@mui/icons-material';
import { getImageUrl } from '../../utils';

const Footer = () => {
  const logo = getImageUrl('pilates-logo-blue.png');

  return (
    <Box sx={{ backgroundColor: '#f7f7f7', padding: { xs: '3rem 1rem', md: '4rem 0' }, borderTop: '1px solid #e0e0e0' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between" alignItems="center">
          {/* Logo and Contact Information */}
          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' }, animation: 'fadeIn 1s ease-in' }}>
            <img src={logo} alt="Pilates Höganäs" style={{ height: '56px', marginBottom: '1rem' }} />
            <Typography variant="body2" sx={{ color: '#555', display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: { xs: 'center', md: 'flex-start' }, marginBottom: '0.5rem' }}>
              <LocationOn sx={{ color: '#00b5bf' }} /> Adress: Litteraturvägen 4A, 263 35 Höganäs
            </Typography>
            <Typography variant="body2" sx={{ color: '#555', display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: { xs: 'center', md: 'flex-start' }, marginBottom: '0.5rem' }}>
              <Phone sx={{ color: '#00b5bf' }} /> Telefon: 0707 94 52 09
            </Typography>
            <Typography variant="body2" sx={{ color: '#555', display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: { xs: 'center', md: 'flex-start' } }}>
              <MailOutline sx={{ color: '#00b5bf' }} /> E-post: <MUILink href="mailto:info@pilateshoganas.se" sx={{ color: '#00b5bf' }}>info@pilateshoganas.se</MUILink>
            </Typography>
          </Grid>

          {/* Social Media Section */}
          <Grid item xs={12} md={4} sx={{ textAlign: 'center', animation: 'fadeIn 1s ease-in 0.5s' }}>
            <Typography variant="h6" gutterBottom sx={{ color: '#007C85', fontWeight: 'bold', marginBottom: '1rem', textTransform: 'uppercase', letterSpacing: '0.1em' }}>
              Social Media
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1.5rem' }}>
              <MUILink href="https://www.instagram.com/pilateshoganas/?hl=sv" sx={{ color: '#00b5bf', transition: 'color 0.3s ease', '&:hover': { color: '#007C85' } }}>
                <Instagram fontSize="large" />
              </MUILink>
              <MUILink href="https://www.facebook.com/profile.php?id=100063455831177" sx={{ color: '#00b5bf', transition: 'color 0.3s ease', '&:hover': { color: '#007C85' } }}>
                <Facebook fontSize="large" />
              </MUILink>
            </Box>
          </Grid>
        </Grid>

        {/* Divider */}
        <Box sx={{ width: '100%', height: '1px', backgroundColor: '#e0e0e0', margin: { xs: '2rem 0', md: '3rem 0' }, animation: 'fadeIn 1s ease-in 1s' }} />

        {/* Copyright and Links */}
        <Grid container justifyContent="center">
          <Typography variant="body2" align="center" sx={{ color: '#555', display: 'flex', flexDirection: 'column', alignItems: 'center', animation: 'fadeIn 1s ease-in 1.5s' }}>
            &copy; {new Date().getFullYear()} Pilates Höganäs - Website created by KristinSigurdar
            <Box sx={{ marginTop: '0.5rem', display: 'flex', gap: '1.5rem' }}>
              <MUILink href="mailto:kristin.sigurdardottir00@gmail.com" sx={{ color: '#00b5bf', display: 'flex', alignItems: 'center', transition: 'color 0.3s ease', '&:hover': { color: '#007C85' } }}>
                <MailOutline fontSize="small" />
                <Typography variant="body2" sx={{ marginLeft: '0.5rem' }}>kristin.sigurdardottir00@gmail.com</Typography>
              </MUILink>
              <MUILink href="https://www.linkedin.com/in/kristin-sigurdar/" sx={{ color: '#00b5bf', display: 'flex', alignItems: 'center', transition: 'color 0.3s ease', '&:hover': { color: '#007C85' } }}>
                <LinkedIn fontSize="small" />
                <Typography variant="body2" sx={{ marginLeft: '0.5rem' }}>LinkedIn</Typography>
              </MUILink>
            </Box>
          </Typography>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
